import React from 'react'
// import _ from "lodash";
// import path from "path";
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout.js'

export default class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.asciidoc
    // const images = _.map(post.children, (i) => ({
    //   ...i,
    //   relativePath: `./${path.relative(post.parent.dir, i.absolutePath)}`,
    // }));

    // const finalHtml = _.reduce(
    //   images,
    //   (h, img) => _.replace(h, img.relativePath, img.publicURL),
    //   post.html
    // );
    return (
      <Layout title={post.document.title}>
        <article className="h-entry">
          <header>
            <h1 className="p-name">{post.document.title}</h1>
            <p className="subtitle">
              <span className="p-author">{post.author.fullName}</span>,{' '}
              <time className="dt-published" dateTime={post.revision.isoDate}>
                {post.revision.date}
              </time>
              .{' '}
              <small>
                Tagged{' '}
                <ul className="inline-tags">
                  {post.fields?.tags?.map((tag) => (
                    <li key={tag}>
                      <Link className="p-category" to={`/tags/${tag}`}>
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </small>
            </p>
          </header>
          <div
            className="e-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </article>
        <hr />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    asciidoc(fields: { slug: { eq: $slug } }) {
      id
      html
      document {
        title
      }
      author {
        fullName
      }
      revision {
        isoDate: date(formatString: "YYYY-MM-DD")
        date(formatString: "MMMM DD, YYYY")
      }
      fields {
        tags
      }
    }
  }
`
